import React from 'react'

import { Container } from 'components/System'

const Phone = () => (
  <svg
    class="flex-shrink-0 h-6 w-6 text-purple-700"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    />
  </svg>
)

const Email = () => (
  <svg
    class="flex-shrink-0 h-6 w-6 text-purple-700"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
)

const Component = ({ title, body }) => (
  <div className="py-8 md:py-16 lg:py-24">
    <Container>
      <div className="space-y-12">
        <div className="text-center space-y-4">
          <h2 className="h2">
            Kontakt <br />
          </h2>
          <p className="text-lg">
            Wenn sie Fragen haben kontaktieren Sie uns gerne unter den folgenden
            Kanälen:
          </p>
        </div>

        <div className="max-w-2xl mx-auto flex justify-center">
          <dl class="inline-block space-y-4">
            <dt class="sr-only">Telefon</dt>
            <dd class="flex space-x-4">
              <Phone />
              <a href="tel:+431997162020" className="hover:underline">
                01 997 1620 - 20
              </a>
            </dd>
            <dt class="sr-only">E-Mail</dt>
            <dd class="flex space-x-4">
              <Email />
              <a
                href="mailto:team@besucherliste.at"
                className="hover:underline"
              >
                team@besucherliste.at
              </a>
            </dd>
          </dl>
        </div>
      </div>
    </Container>
  </div>
)

export default Component
