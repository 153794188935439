import React from 'react'

import { Container } from 'components/System'

const faqs = [
  {
    question: 'Benötige ich eine digitale Gästeliste?',
    answer: (
      <>
        Nein, Sie können auch weiterhin analog ein Formblatt bereitstellen um
        Ihrer Registrierungspflicht nachzukommen. Die Stadt Wien hat hier
        Formblätter zur Aufnahme von Kontaktdaten zur Verfügung gestellt:
        <ul className="list-disc mt-2 space-y-1">
          <li>
            <a
              id="downloadTemplateBW"
              className="link"
              href="/downloads/Gastroformblatt-Vorlage-Coronavirus-BW.pdf"
              download
            >
              Kontaktdaten Gäste - Formular zum Ausdrucken in{' '}
              <strong>Schwarz und Weiß</strong>
            </a>
          </li>
          <li>
            <a
              id="downloadTemplateColor"
              className="link"
              href="/downloads/Gastroformblatt-Vorlage-Coronavirus-Farbe.pdf"
              download
            >
              Kontaktdaten Gäste - Formular zum Ausdrucken in Farbe
            </a>
          </li>
          <li>
            Mehr Infos:{' '}
            <a
              href="https://coronavirus.wien.gv.at/site/wirtschaft"
              className="link"
              target="_blank"
              rel="external noreferrer"
            >
              https://coronavirus.wien.gv.at/site/wirtschaft
            </a>
          </li>
        </ul>
      </>
    ),
  },
  {
    question:
      'Muss ich mich zwischen analoger und digitaler Version entscheiden?',
    answer:
      'Nein, Sie können natürlich sowohl analoge Formblätter als auch Besucherliste.at verwenden, wenn Sie der Meinung sind das ein Teil Ihrer Besucher keine Smartphones verwenden möchte. Wir arbeiten auch an einer Lösung wie es zukünftig möglich sein wird analoge Formblätter einzuscannen.',
  },
  {
    question:
      'Müssen Besucher eine eigene App installieren um ihre Daten eingeben zu können?',
    answer:
      'Nein, die Eingabe der Daten kann direkt über den Browser am Smartphone erfolgen. Es ist keine Installation erforderlich. Der Prozess ist so gestaltet, dass möglichst wenig Zeit für das Ausfüllen des digitalen Formblatts aufgewendet werden muss.',
  },
  {
    question: 'Ist Besucherliste.at nur für Lokale geeignet?',
    answer:
      'Nein, unsere Lösung kann neben der Verwendung als Gastroliste auch zur Registrierung von Gästen in allen Branchen eingesetzt werden. Denkbar ist die Verwendung bei Veranstaltungen, im Kontext von Büro-Feiern sowie Hochzeiten.',
  },
  {
    question: 'In welchen Regionen kann Besucherliste.at verwendet werden?',
    answer:
      'Die Software wurde zur Erfüllung der neuen Covid19 bedingten Regelungen in Wien und Niederösterreich gebaut. Der Einsatz in anderen Bundesländern wie Tirol oder Ländern wie Deutschland ist natürlich möglich.',
  },
  {
    question:
      'Kann die Besucherliste auch für Sport-Veranstaltungen verwendet werden?',
    answer:
      'Ja, für Veranstaltungen aller Art (Fußball-Spiel, Eishockey, …) kann die Liste verwendet werden um die Registrierung von Gästen zu erleichtern.',
  },
  {
    question: 'Wer betreibt Besucherliste.at?',
    answer: 'Ein Team aus erfahrenen Entwicklern aus dem B2B-Umfeld.',
  },
]

const Toggle = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21.9707C16.5228 21.9707 21 17.4936 21 11.9707C21 6.44786 16.5228 1.9707 11 1.9707C5.47715 1.9707 1 6.44786 1 11.9707C1 17.4936 5.47715 21.9707 11 21.9707Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 7.9707V15.9707"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11.9707H15"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Component = ({ title, body }) => (
  <div className="py-8 md:py-16 lg:py-24">
    <Container>
      <div className="space-y-16">
        <div className="text-center">
          <h2 className="h2">
            Kurze Fragen. <br />
            Kurze Antworten.
          </h2>
        </div>

        <div className="max-w-2xl mx-auto">
          <ul className="space-y-12">
            {faqs.map(({ question, answer }, index) => (
              <li key={index} className="space-y-4">
                <div className="flex">
                  <div className="flex-grow">
                    <h4 className="h4">{question}</h4>
                  </div>
                  <div className="flex-shrink-0">
                    <Toggle />
                  </div>
                </div>
                <div className="pr-8">
                  {typeof answer === 'string' ? <p>{answer}</p> : answer}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  </div>
)

export default Component
