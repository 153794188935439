import React from 'react'

import { Container, Button } from 'components/System'

const CheckmarkCircle = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#DEF7EC" />
    <path
      d="M21.3333 12L14 19.3333L10.6666 16"
      stroke="#0E9F6E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Checkmark = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.33334L4.33333 8.66668L1 5.33334"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const uspPoints = [
  'Kein händisches Ausfüllen von Formblättern zur Corona Kontaktverfolgung',
  'Gästeregistrierung per Smartphone in 10 Sekunden - ganz ohne App.',
  'Europäische Server und sichere Datenlöschung nach 28 Tagen',
]

const callToActionPoints = [
  '14 Tage kostenlos testen',
  'unlimitierte Anzahl an BesucherInnen',
  'keine Kreditkarte für Registrierung notwendig',
]

const Signup = () => {
  return (
    <div className="bg-purple-100 rounded-lg max-w-md lg:mx-auto">
      <div className="p-6 md:p-12 space-y-6">
        <div className="space-y-2">
          <h2 className="h3">Melden Sie sich an</h2>
          <p className="text-sm">
            Steigen Sie jetzt auf die{' '}
            <span className="mark">digitale Corona Gästeliste</span> um.
          </p>
        </div>

        <div className="flex items-center space-x-2">
          <div className="h0 text-purple-700">€20</div>
          <div className="text-purple-500 text-xs">
            pro Monat <br /> pro Standort
          </div>
        </div>

        <ul className="space-y-2">
          {callToActionPoints.map((usp, index) => (
            <li key={index} className="flex items-center space-x-4">
              <span className="text-purple-700">
                <Checkmark />
              </span>
              <p className="text-sm">{usp}</p>
            </li>
          ))}
        </ul>

        <div className="text-center space-y-2">
          <Button
            id="leadSignup"
            fullWidth
            href="https://app.besucherliste.at/users/sign_up"
          >
            Jetzt registrieren
          </Button>
          <div className="space-x-1">
            <span className="text-sm">oder</span>
            <a
              href="https://app.besucherliste.at"
              className="inline-block underline text-purple-700"
            >
              Einloggen
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const Component = ({ title, body }) => (
  <div className="py-4 sm:py-8 md:py-16 lg:py-24">
    <Container>
      <div className="grid lg:grid-cols-12 gap-8 items-center">
        <div className="col-span-5">
          <div className="space-y-6">
            <h1 className="h2">
              Die digitale Corona Gästeliste für ganz{' '}
              <span className="mark">Österreich</span>
            </h1>

            <div className="text-lg space-y-4">
              <p>
                Erstellen Sie schnell und einfach eine{' '}
                <strong>
                  verordnungskonforme, digitale Gastroliste für Ihr Lokal
                </strong>
                .<br />
                Besucherliste.at ist das verpflichtende Formblatt als
                Online-Tool für Wien, Niederösterreich, Oberösterreich, Salzburg und Tirol.
              </p>
            </div>

            <ul className="space-y-4">
              {uspPoints.map((usp, index) => (
                <li key={index} className="flex items-center space-x-4">
                  <span>
                    <CheckmarkCircle />
                  </span>
                  <p>{usp}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-span-7">
          <Signup />
        </div>
      </div>
    </Container>
  </div>
)

export default Component
