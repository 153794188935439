import React from 'react'

import { Container } from 'components/System'
import BusinessIcon from 'images/icons/business.inline.svg'
import CustomerIcon from 'images/icons/customer.inline.svg'

const benefits = {
  business: [
    'Set-Up in unter 2 Minuten',
    'Keine Software-Installation notwendig',
    'Komplette Verwaltung über Smartphone/Tablet möglich',
    '14 Tage kostenlos testen ohne Risiko',
    'Anpassbare Willkommens- und Bestätigungsnachricht',
    'Verwaltung mehrerer Standorte',
    'Kein Aufbewahren von datenschutzrechtlich heiklen Formblättern',
    'Zugriff nur für autorisierte Mitarbeiter und Mitarbeiterinnen',
    ['Scannen von Formblättern über Smartphone', false],
    ['Anlegen von einzelnen Tischen im Lokal', false],
  ],
  customer: [
    'Daten werden automatisch nach 28 Tagen gelöscht',
    'Formular in Deutsch und Englisch',
    'Keine Einsicht der Daten für andere Besucher und Besucherinnen',
    'Keine Einsicht der Daten für alle Mitarbeiter und Mitarbeiterinnen',
    'Kein Ausfüllen von Formblättern mittels geteilter Kugelschreiber',
    'Kein lästiges Ausfüllen beim nächsten Besuch - einmalige Eingabe deiner Daten für alle teilnehmenden Lokale',
  ],
}

const Checkmark = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 1.33337L4.33333 8.66671L1 5.33337"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Component = ({ title, body }) => (
  <div className="py-8 md:py-16 lg:py-24">
    <Container>
      <div className="space-y-16">
        <div className="text-center">
          <h2 className="h2">Ihre Vorteile auf einen Blick.</h2>
        </div>

        <div className="grid lg:grid-cols-2 gap-8">
          <div className="col-span-1">
            <div className="max-w-xl p-6 md:p-12 mx-auto bg-pink-100 rounded-lg space-y-8">
              <div className="flex items-center text-pink-800">
                <div className="flex-grow">
                  <h3 className="h3">BetreiberInnen</h3>
                  <p className="text-lg">Vorteile für Sie und Ihren Betrieb</p>
                </div>
                <div className="flex-shrink-0">
                  <BusinessIcon />
                </div>
              </div>
              <ul className="space-y-4">
                {benefits.business.map((usp, index) => (
                  <li key={index} className="flex items-center space-x-4">
                    <span className="text-pink-800">
                      <Checkmark />
                    </span>

                    {typeof usp === 'string' ? (
                      <p>{usp}</p>
                    ) : (
                      <div className="">
                        <span className="pr-2">{usp}</span>
                        <span
                          className="inline-block bg-green-400 text-white text-xs leading-none py-1 px-2 rounded-full whitespace-no-wrap align-middle"
                          style={{ fontSize: 10 }}
                        >
                          In Kürze
                        </span>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-span-1">
            <div className="max-w-xl p-6 md:p-12 mx-auto bg-purple-100 rounded-lg space-y-8">
              <div className="flex items-center text-purple-800">
                <div className="flex-grow">
                  <h3 className="h3">BesucherInnen</h3>
                  <p className="text-lg">Vorteile für Ihre Kundschaft</p>
                </div>
                <div className="flex-shrink-0">
                  <CustomerIcon />
                </div>
              </div>
              <ul className="space-y-4">
                {benefits.customer.map((usp, index) => (
                  <li key={index} className="flex items-center space-x-4">
                    <span className="text-purple-800">
                      <Checkmark />
                    </span>
                    <p>{usp}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
)

export default Component
